import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./features@gen.scss";

const GeneratedProductFeatures = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>Features - PagerDuty正規代理店 - 株式会社Digital Stacks</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta property="twitter:description" content="PagerDutyの製品機能" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="PagerDutyの製品機能" />

        <meta
          property="og:title"
          content="Features - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta property="og:description" content="PagerDutyの製品機能" />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="PagerDutyの製品機能"
        overview=""
      />

      <div className="featuresWrapper">
        <div className="features-container">
          <div className="text-right-1">
            <div className="text-part">
              <h4 className="text-right-title">
                Operations Command
                <br />
                Console
              </h4>
              <div className="text-right-para">
                アプリケーション、サービス、インフラ等のインシデント対応のワークフローを一元管理
              </div>
              <div className="right-cta">
                <a href="/product/applications">詳しくはこちら</a>
              </div>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/ss_occ_21d9e6cf29.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672991364540&tr=q-100,f-webp,w-720"
                alt="Operations Command Console"
              />
            </div>
          </div>
          <div className="center-1">
            <h3 className="center-title">機能一覧</h3>
            <p className="center-para">
              PagerDutyのインシデント管理プラットフォームは、ノイズを削減し、
              <br />
              インシデントをより迅速に解決するために役立ちます。
            </p>
          </div>
          <div className="three-col-media-contents">
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/event_enrichment_ss_carousel_a4217bbd30.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672991363723&tr=q-100,f-webp"
                  alt="アラートの集約と分類"
                />
              </div>
              <p>
                <a
                  className="title"
                  href="/product/features/event-grouping-and-enrichment"
                >
                  アラートの集約と分類
                </a>
              </p>
              <p className="para">
                イベントを集約し、分類し、相関させ、重要なものを管理する。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/event-grouping-and-enrichment"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_2_1_5d8eaa65de.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672991363826&tr=q-100,f-webp"
                  alt="多様なアラート機能"
                />
              </div>
              <p>
                <a className="title" href="/product/features/reliable-alerting">
                  {" "}
                  多様なアラート機能{" "}
                </a>
              </p>
              <p className="para">適切な情報を適切な人に適切な手段で提供。</p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/reliable-alerting"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_3_1_a50400fea3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672991363743&tr=q-100,f-webp"
                  alt="簡単なスケジューリングと自動エスカレーション"
                />
              </div>
              <h3>
                <a className="title" href="/product/features/easy-scheduling">
                  簡単なスケジューリングと自動エスカレーション
                </a>
              </h3>
              <p className="para">
                オンコールスケジュール、ローテーション、エスカレーションを設定します。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/easy-scheduling"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_4_d23d81cefb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672991762266&tr=q-100,f-webp"
                  alt="モバイルでのインシデント管理"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/mobile-incident-management"
                >
                  モバイルでの
                  <br />
                  インシデント管理
                </a>
              </h3>
              <p className="para">
                優れたユーザーインターゲースで、外出先からインシデントを管理します。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/mobile-incident-management"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_5_bbab4cf010.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672991762221&tr=q-100,f-webp"
                  alt="リアルタイムコラボレーション"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/real-time-collaboration"
                >
                  リアルタイム
                  <br />
                  コラボレーション
                </a>
              </h3>
              <p className="para">
                ChatOpsツール、ヘルプデスクサービスとの連携により、適切なチームに連絡します。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/real-time-collaboration"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_6_e563e77c62.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672991762215&tr=q-100,f-webp"
                  alt="システム＆ユーザーレポート"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/system-and-user-reporting"
                >
                  システム＆ユーザー
                  <br />
                  レポート
                </a>
              </h3>
              <p className="para">
                システムの効率化、従業員の生産性を把握します。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/system-and-user-reporting"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_7b_986a7e909e.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672992390961&tr=q-100,f-webp"
                  alt="信頼性の 高い環境"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/always-on-reliability"
                >
                  信頼性の
                  <br />
                  高い環境
                </a>
              </h3>
              <p className="para">
                高品質なサービスを提供するためのインフラを整備しています。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/always-on-reliability"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/pd_feature_v3_8b_def1fe4fc0.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672992390684&tr=q-100,f-webp"
                  alt="エンタープライズレベルのセキュリティ"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/enterprise-grade-security"
                >
                  エンタープライズレベルのセキュリティ
                </a>
              </h3>
              <p className="para">
                ロールベースのアクセス許可を管理するための管理コントロールを備えています。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/enterprise-grade-security"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/saaf_thumbnail_bbb53095c4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672992390842&tr=q-100,f-webp"
                  alt="監視サービスのグループ化"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/service-and-team-organization"
                >
                  監視サービスの
                  <br />
                  グループ化
                </a>
              </h3>
              <p className="para">
                複数の監視サービスを簡単にグループ化できます。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/service-and-team-organization"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/developer_story_blk_bkgd_a67758809d.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672992615366&tr=q-100,f-webp"
                  alt="プラットフォームの拡張性"
                />
              </div>
              <h3>
                <a
                  className="title"
                  href="/product/features/platform-extensibility"
                >
                  プラットフォームの
                  <br />
                  拡張性
                </a>
              </h3>
              <p className="para">
                あらゆるツールと連携できる信頼できるプラットフォーム
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/platform-extensibility"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/live_call_routing_desktop_black_1_6480dcb7e1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1672992615714&tr=q-100,f-webp"
                  alt="ライブコール ルーティング"
                />
              </div>
              <h3>
                <a className="title" href="/product/features/live-call-routing">
                  ライブコール
                  <br />
                  ルーティング
                </a>
              </h3>
              <p className="para">
                オンコールスケジュールとエスカレーションポリシーを使って、オンコールのレスポンダーにすぐに連絡することができます。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/live-call-routing"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Features/f1d3b430f73af0df7047201779b0150c_291241f9ce.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672992615597&tr=q-100,f-webp"
                  alt="事後検証"
                />
              </div>
              <h3>
                <a className="title" href="/product/features/post-mortems">
                  {" "}
                  事後検証{" "}
                </a>
              </h3>
              <p className="para">
                事後検証プロセスを合理化することで、インシデントを解決し予防する力を高める。
              </p>
              <div className="cta">
                <a
                  className="group flex after:hidden"
                  href="/product/features/post-mortems"
                >
                  詳しくはこちら
                  <svg
                    className="mt-[5px] ml-[5px] fill-[#929292] group-hover:fill-[#4EB346]"
                    width="18px"
                    height="18px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490 490"
                  >
                    <polygon points="96.536,490 403.019,244.996 96.536,0 86.981,11.962 378.496,244.996 86.981,478.038 " />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                    <g />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeatures;
